
<div class="contenido" *ngFor="let persona of personas">
  <div>
    <img [src]="'assets/'+persona.slug + '.jpg'" alt="">
  </div>
  <div>
    <h3> {{ persona.nombre }} </h3>

  <h5 class="text-left"> {{ persona[lang].habilidades }} </h5>

  <p class="text-left" [innerHTML]="persona[lang].perfil"></p>
  </div>

</div>

