<nav class="navbar navbar-expand-lg navbar-light bg-light">

  <div class="">

    <ul class="navbar-nav mr-auto mt-2 mt-lg-0">

    </ul>
<button  [class.btn-info]="lang==='es'" class="es btn btn-sm" (click)="idiomaCont('es')">ES-</button>
<button  [class.btn-info]="lang==='en'" class="en btn btn-sm" (click)="idiomaCont('en')">EN-</button>
  </div>
</nav>

<router-outlet></router-outlet>


