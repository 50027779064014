<nav class="navbar fixed-bottom navbar-light bg-light footerretina" >


    <a href="https://cinando.com/en/Company/cabecitanegra_producciones_37134/Detail" target="_blank"><img src="assets/cinando.png" alt="Cabecitanegra en CINANDO"></a>
    |
    <a href="https://vimeo.com/428813760" target="_blank"><img src="assets/vimeo.png" alt=" Cabecitanegra en VIMEO"></a> |
    <span>Bogotá - Colombia</span> |
    <span>info@cabecitanegraproducciones.com</span>



</nav>
